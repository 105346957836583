import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useParams, useHistory, Switch } from 'react-router-dom';
import { pathRouterShit } from '@util/utils';
import { Typography, PrivateRoute } from '@common/components/';
import useDocMembershipSuspendApi from '@apis/useDocMembershipSuspendApi';
import MembershipSuspensionHistory from './MembershipSuspensionHistory';
import MembershipSuspensionContent from './MembershipSuspensionContent';
import { DOC_MEMBERSHIP_SUSPEND_LINK } from '../staticData';
import { BlankFormBox, SearchBar } from '@icoach/documents/components/';
import { ConfirmTemporaryContractDialog } from '@icoach/documents/dialog';
import {
    DOCUMENTS_MEMBERSHIPSUSPENSION_ROUTER,
    DOCUMENTS_MEMBERSHIPSUSPENSION_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER,
    DOCUMENTS_MEMBERSHIPSUSPENSION_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER,
    DOCUMENTS_MEMBERSHIPSUSPENSION_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER,
} from '@icoach/router/routerPath';

const MembershipSuspensionPage = () => {
    const { getInitSuspendOptionApi, getDocTemporarySuspendContractApi, deleteDocTemporarySuspendContractApi } = useDocMembershipSuspendApi();
    const history = useHistory();
    const { peopleType: peopleTypeProps, referenceID: referenceIDProps } = useParams();
    const [options, setOptions] = useState({});
    const [openDialog, setOpenDialog] = useState(false); //有暫存資料時的彈出視窗
    const [isMounted, setMounted] = useState(false);
    const searchRef = useRef([]);
    const temporaryIDRef = useRef(0);

    const checkPeopleType = (opts) => {
        const { targetOptions = [] } = opts;
        const isInclude = targetOptions.find(({ value }) => String(peopleTypeProps) === String(value));
        const defaultPeopleType = 2; // 預設值 有效會員
        if (!peopleTypeProps || !isInclude) {
            history.replace(`${DOCUMENTS_MEMBERSHIPSUSPENSION_ROUTER}${defaultPeopleType}`);
        }
    };

    // 重設 按鈕
    const handleResetClick = (type) => {
        const path = `${DOCUMENTS_MEMBERSHIPSUSPENSION_ROUTER}${type}`;
        history.replace(path);
    };

    // 搜尋 按鈕
    const handleSearchHistoryClick = (id) => {
        const { targetID, targetType } = searchRef.current.getResult();
        const params = {
            peopleType: targetType,
            referenceID: id || targetID,
        };
        const path = pathRouterShit(DOCUMENTS_MEMBERSHIPSUSPENSION_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER, params);
        history.push(path);
    };

    // 新增 按鈕
    const handleCreateClick = () => {
        const { targetID: id, targetType: type } = searchRef.current.getResult();
        const params = { id, type };
        doCreateBlankContractApi(params, type, id);
    };

    // 彈出視窗 要刪除暫存資料
    const handleDialogNoClick = () => {
        const params = {
            id: referenceIDProps,
            type: peopleTypeProps,
        };
        doDeleteTemporaryDataApi(params);
    };

    // 彈出視窗 讀取上次暫存資料繼續編輯
    const handleDialogYseClick = () => {
        if (!temporaryIDRef.current) return;
        const parmas = {
            peopleType: peopleTypeProps,
            referenceID: referenceIDProps,
            contractID: temporaryIDRef.current,
        };
        const path = pathRouterShit(DOCUMENTS_MEMBERSHIPSUSPENSION_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER, parmas);
        setOpenDialog(false);
        history.push(path);
    };

    // 刪除暫存合約資料
    const doDeleteTemporaryDataApi = async (parmas) => {
        const resp = await deleteDocTemporarySuspendContractApi(parmas);
        if (resp) {
            const path = pathRouterShit(DOCUMENTS_MEMBERSHIPSUSPENSION_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER, {
                peopleType: peopleTypeProps,
                referenceID: referenceIDProps,
                contractID: 0,
            });
            setOpenDialog(false);
            history.replace(path);
        }
    };

    // 新增空白頁面
    const doCreateBlankContractApi = async (params, type, id) => {
        let temporaryID;
        temporaryIDRef.current = 0;
        temporaryID = await getDocTemporarySuspendContractApi(params);
        if (temporaryID !== 0 && !!temporaryID) {
            temporaryIDRef.current = temporaryID;
            setOpenDialog(true);
        } else {
            const path = pathRouterShit(DOCUMENTS_MEMBERSHIPSUSPENSION_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER, {
                peopleType: type,
                referenceID: id,
                contractID: 0,
            });
            history.push(path);
        }
    };

    // 初始search options
    const doInitOptionsApi = async () => {
        const opts = await getInitSuspendOptionApi();
        if (opts) {
            checkPeopleType(opts);
            setOptions(opts);
        }
        setMounted(true);
    };

    const useSearchBarMemo = useMemo(
        () => {
            return (
                <SearchBar
                    ref={searchRef}
                    className="mb-4"
                    sourceOption={options.targetOptions}
                    onResetEvent={handleResetClick}
                    onCreateEvent={handleCreateClick}
                    onSearchHistoryEvent={handleSearchHistoryClick}
                    routerPath={DOCUMENTS_MEMBERSHIPSUSPENSION_ROUTER}
                />
            );
        },
        // eslint-disable-next-line
        [options]
    );

    useEffect(
        () => {
            doInitOptionsApi();
            return () => setMounted(false);
        },
        // eslint-disable-next-line
        []
    );

    return (
        <div className="container contract-membership-suspension main-container-spacing">
            {isMounted && (
                <React.Fragment>
                    <header className={'page-header'}>
                        <Typography className={'page-header-title'} variant={'h3'}>
                            會籍暫停申請書
                        </Typography>
                        <div className="btn-group">
                            <BlankFormBox source={DOC_MEMBERSHIP_SUSPEND_LINK} />
                        </div>
                    </header>
                    {useSearchBarMemo}
                    <Switch>
                        <PrivateRoute
                            exact
                            path={DOCUMENTS_MEMBERSHIPSUSPENSION_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER}
                            as={MembershipSuspensionHistory}
                            onBlankContractClick={handleCreateClick}
                        />
                        <PrivateRoute
                            exact
                            path={DOCUMENTS_MEMBERSHIPSUSPENSION_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER}
                            as={MembershipSuspensionContent}
                            onReturnHistory={() => handleSearchHistoryClick()}
                        />
                        <PrivateRoute
                            exact
                            path={DOCUMENTS_MEMBERSHIPSUSPENSION_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER}
                            as={MembershipSuspensionContent}
                            isReadOnly
                            onReturnHistory={() => handleSearchHistoryClick()}
                        />
                    </Switch>
                </React.Fragment>
            )}
            <ConfirmTemporaryContractDialog
                open={openDialog}
                handleClose={() => setOpenDialog(false)}
                handleClickYes={handleDialogYseClick}
                handleClickNo={handleDialogNoClick}
            />
        </div>
    );
};

export default MembershipSuspensionPage;
