import React, { useState, useEffect, useImperativeHandle, useRef } from 'react';
import { v4 as uuid } from 'uuid';

const PrivateInformationBox = React.forwardRef((props, ref) => {
    let {
        defaultValue: defaultProps,
        placeholder: placeholderProps,
        value: valueProps,
        as: Component,
        onChange: onChangeProps,
        isError: propsError = false,
        required: requiredProps,
        ...other
    } = props;
    const [valueState, setValue] = useState({
        placeholder: placeholderProps,
        key: uuid(),
    });
    const [required, setRequired] = useState(requiredProps);
    const { placeholder, key, ...otherValue } = valueState;
    const isEditRef = useRef(false);
    const filedRef = useRef(null);

    const checkDefaultValue = (defaultValue, value) => {
        return !(!defaultValue && !value);
    };

    const onChange = (e) => {
        if (!isEditRef.current) {
            setRequired(requiredProps);
            setValue((old) => ({
                ...old,
                placeholder: '',
            }));
            isEditRef.current = true;
        }
        if (typeof onChangeProps === 'function') onChangeProps(e);
    };

    useImperativeHandle(
        ref,
        () => ({
            isError: () => {
                let isError = propsError;
                if (defaultProps && isEditRef.current && filedRef.current) {
                    isError = filedRef.current.isError();
                }

                return isError;
            },
            getResult: () => {
                let val;
                if (isEditRef.current) {
                    val = filedRef.current.getResult();
                } else {
                    val = defaultProps || valueProps;
                }
                return val;
            },
            getName: () => filedRef.current.name,
            getId: () => filedRef.current.id,
            node: () => filedRef.current,
        }),
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {
            if (!defaultProps && !valueProps) return null;
            if (defaultProps && valueProps) throw new Error('value 與 defaultValue 擇一設定');

            const reg = /\*+/gm;
            const newObj = {};

            if (reg.test(defaultProps) || reg.test(valueProps)) {
                newObj.placeholder = defaultProps || valueProps;
            } else if (defaultProps) {
                newObj.defaultValue = defaultProps;
            } else if (defaultProps) {
                newObj.value = defaultProps;
            }

            setValue((old) => {
                return {
                    ...old,
                    ...newObj,
                    key: uuid(),
                };
            });
        },
        // eslint-disable-next-line
        [defaultProps, valueProps]
    );

    useEffect(
        () => {
            setRequired(!checkDefaultValue(defaultProps, valueProps) && requiredProps);
        },
        // eslint-disable-next-line
        [requiredProps]
    );

    return (
        <Component
            key={key}
            ref={filedRef}
            isError={propsError}
            {...other}
            {...otherValue}
            inputProps={{ placeholder: placeholder }}
            onChange={onChange}
            required={required}
        />
    );
});
export default PrivateInformationBox;
