import React, { useState, useRef, useMemo, useEffect } from 'react';
import { useParams, useHistory, Switch } from 'react-router-dom';
import { Typography, PrivateRoute } from '@common/components/';
import useDocHealthDeclarationApi from '@apis/useDocHealthDeclarationApi';
import { pathRouterShit } from '@util/utils';
import HealthSurveyContent from '@icoach/documents/healthDeclaration/HealthSurveyContent';
import HealthDeclarationHistory from '@icoach/documents/healthDeclaration/HealthDeclarationHistory';
import DocumentBlankValueButton from '@icoach/documents/components/DocumentBlankValueButton';
import { DOC_HEALTH_DECLARATION_LINK } from '@icoach/documents/membership/staticData';
import { SearchBar } from '@icoach/documents/components/';
import { ConfirmTemporaryContractDialog } from '@icoach/documents/dialog/';
import {
    DOCUMENTS_HEALTHDECLARATION_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER,
    DOCUMENTS_HEALTHDECLARATION_ROUTER,
    DOCUMENTS_HEALTHDECLARATION_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER,
    DOCUMENTS_HEALTHDECLARATION_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER,
} from '@icoach/router/routerPath';

const HealthDeclarationPage = (props) => {
    const { getInitHealthDeclarationOptionApi, getDocHealthDeclarationTemporaryApi, deleteDocHealthDeclarationTemporaryApi } = useDocHealthDeclarationApi();
    const { peopleType: peopleTypeProps, referenceID: referenceIDProps } = useParams();
    const history = useHistory();
    const [options, setOptions] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [isMounted, setMounted] = useState(false);
    const searchRef = useRef(null);
    const temporaryIDRef = useRef(0);

    const checkPeopleType = (opts) => {
        const { targetOptions = [] } = opts;
        const isInclude = targetOptions.find(({ value }) => String(peopleTypeProps) === String(value));
        const defaultPeopleType = 2; // 預設值 會員
        if (!peopleTypeProps || !isInclude) {
            history.replace(`${DOCUMENTS_HEALTHDECLARATION_ROUTER}${defaultPeopleType}`);
        }
    };

    // 重設 按鈕
    const handleResetClick = (type) => {
        const path = `${DOCUMENTS_HEALTHDECLARATION_ROUTER}${type}`;
        history.replace(path);
    };

    // 搜尋 按鈕
    const handleSearchHistoryClick = (id) => {
        const { targetID, targetType: type } = searchRef.current.getResult();
        const params = {
            peopleType: type,
            referenceID: id || targetID,
        };
        const path = pathRouterShit(DOCUMENTS_HEALTHDECLARATION_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER, params);
        history.replace(path);
    };

    // 按鈕 新增
    const handleCreateClick = () => {
        const { targetID: id, targetType: type } = searchRef.current.getResult();
        const params = { id, type };
        doCreateBlankContractApi(params, type, id);
    };

    // 彈出視窗 要刪除暫存資料
    const handleDialogNoClick = () => {
        const params = {
            id: referenceIDProps,
            type: peopleTypeProps,
        };
        doDeleteTemporaryDataApi(params);
    };

    // 彈出視窗 讀取上次暫存資料繼續編輯
    const handleDialogYseClick = () => {
        if (!temporaryIDRef.current) return;
        const parmas = {
            peopleType: peopleTypeProps,
            referenceID: referenceIDProps,
            contractID: temporaryIDRef.current,
        };
        const path = pathRouterShit(DOCUMENTS_HEALTHDECLARATION_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER, parmas);
        setOpenDialog(false);
        history.push(path);
    };

    // 新增空白頁面
    const doCreateBlankContractApi = async (params, type, id) => {
        let temporaryID;
        temporaryIDRef.current = 0;
        temporaryID = await getDocHealthDeclarationTemporaryApi(params);
        if (temporaryID !== 0 && !!temporaryID) {
            temporaryIDRef.current = temporaryID;
            setOpenDialog(true);
        } else {
            const path = pathRouterShit(DOCUMENTS_HEALTHDECLARATION_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER, {
                peopleType: type,
                referenceID: id,
                contractID: 0,
            });
            history.push(path);
        }
    };

    // 刪除暫存合約資料
    const doDeleteTemporaryDataApi = async (parmas) => {
        const resp = await deleteDocHealthDeclarationTemporaryApi(parmas);
        if (resp) {
            const path = pathRouterShit(DOCUMENTS_HEALTHDECLARATION_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER, {
                peopleType: peopleTypeProps,
                referenceID: referenceIDProps,
                contractID: 0,
            });
            setOpenDialog(false);
            history.push(path);
        }
    };

    const initOptionDataApi = async () => {
        const opts = await getInitHealthDeclarationOptionApi();
        if (opts) {
            checkPeopleType(opts);
            setOptions(opts);
        }
        setMounted(true);
    };

    const useSearchBarMemo = useMemo(
        () => {
            return (
                <SearchBar
                    ref={searchRef}
                    className="mb-4"
                    sourceOption={options.targetOptions}
                    onResetEvent={handleResetClick}
                    onCreateEvent={handleCreateClick}
                    onSearchHistoryEvent={handleSearchHistoryClick}
                    routerPath={DOCUMENTS_HEALTHDECLARATION_ROUTER}
                />
            );
        },
        // eslint-disable-next-line
        [options]
    );

    useEffect(
        () => {
            initOptionDataApi();
            return () => setMounted(false);
        },
        // eslint-disable-next-line
        []
    );

    return (
        <div className="container main-container-spacing doc-health-declaration">
            {isMounted && (
                <React.Fragment>
                    <header className={'page-header'}>
                        <Typography className={'page-header-title'} variant={'h3'}>
                            健康狀況申告書
                        </Typography>
                        <DocumentBlankValueButton sourceData={DOC_HEALTH_DECLARATION_LINK} />
                    </header>
                    {useSearchBarMemo}
                    <Switch>
                        <PrivateRoute
                            exact
                            path={DOCUMENTS_HEALTHDECLARATION_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER}
                            as={HealthDeclarationHistory}
                            onBlankContractClick={handleCreateClick}
                            onRefresh={handleSearchHistoryClick}
                        />
                        <PrivateRoute
                            exact
                            path={DOCUMENTS_HEALTHDECLARATION_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER}
                            as={HealthSurveyContent}
                            onReturnHistory={handleSearchHistoryClick}
                        />
                        <PrivateRoute
                            exact
                            path={DOCUMENTS_HEALTHDECLARATION_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER}
                            as={HealthSurveyContent}
                            isReadOnly={true}
                            onReturnHistory={handleSearchHistoryClick}
                        />
                    </Switch>
                </React.Fragment>
            )}
            <ConfirmTemporaryContractDialog
                open={openDialog}
                handleClose={() => setOpenDialog(false)}
                handleClickNo={handleDialogNoClick}
                handleClickYes={handleDialogYseClick}
            />
        </div>
    );
};

export default HealthDeclarationPage;
