import React, { useMemo, useRef } from 'react';
import { isEmpty } from 'lodash';
import { useAccountBookContext } from '@icoach/accountBook/components/AccountBookContext';
import { MEMBERS_MEMBER_DETAIL_URL } from '@icoach/router/MembersRouter';
import MemberDialogBox from '@icoach/members/dialog/MemberDialogBox';
import Tablenization from '@icoach/components/Tablenization';
import { MorePageBox, LabelHeaderCheckBox, useLabelCheckBox } from '@icoach/components/';
import { Stack, Tooltip, IconButton, Box } from '@common/components/';
import { PaperPlane as PaperPlaneIcon } from '@common/SvgIcon/';
import ScrollerArrowButton from '@icoach/inbody/components/ScrollerArrowButton';
import { ListInfo } from '@icoach/accountBook/components/';

const AccountBookOverviewFilterTable = React.memo((props) => {
    const { sourceData, rowsLabel, sortOption, bookmark, setBookmarkNum, setBookmarkSortBy, setContactState, reSetCheckedItemsRef, refresh } =
        useAccountBookContext();
    const { sortBy } = bookmark;
    const { list: rowsData = [], pageIndex: page = 1, totalPage: count, pageSize, totalCount } = sourceData;
    const { checkBoxsRef, headerCheckBoxRef, onHeaderChange, onCellChange, getCheckedItems, getCheckedSourceData, doSaveCheckedItem, doClearCheckedItems } =
        useLabelCheckBox();
    const memberDialogBoxRef = useRef(null);
    const scrollerArrowRef = useRef(null);
    reSetCheckedItemsRef(doClearCheckedItems);

    const handlePageChange = (e, num) => {
        doSaveCheckedItem();
        setBookmarkNum(num);
    };

    const handleOrderDialogOpen = (mID) => {
        memberDialogBoxRef.current.openOrderDialog({ memberID: mID }, () => {
            doClearCheckedItems();
            refresh();
        });
    };

    const handleSendSMSClick = () => {
        let params = getCheckedSourceData();
        if (params.length === 0) return;
        params = params.map((target) => ({ id: target.memberID, type: 2, name: target.nickName || target.memberName }));
        memberDialogBoxRef.current.openSmsDialog(params);
    };

    const headerRow = useMemo(
        () => {
            doClearCheckedItems();
            return [...(rowsLabel || [])];
        },
        // eslint-disable-next-line
        [rowsLabel],
    );

    return (
        <React.Fragment>
            {headerRow.length !== 0 && (
                <React.Fragment>
                    <Stack className={'mb-1'} alignItems={'center'} justifyContent={'space-between'}>
                        {!isEmpty(rowsData) && (
                            <Stack className="pl-4 pb-1">
                                <LabelHeaderCheckBox ref={headerCheckBoxRef} onChange={onHeaderChange} />
                                <Stack>
                                    <Tooltip title="群發送簡訊">
                                        <IconButton onClick={handleSendSMSClick}>
                                            <PaperPlaneIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            </Stack>
                        )}
                        <ListInfo
                            className={'ml-auto'}
                            pageSize={pageSize}
                            pageIndex={page}
                            totalCount={totalCount}
                            sortDefaultValue={sortBy}
                            sortOptions={sortOption}
                            sortOnChange={(val) => {
                                doClearCheckedItems();
                                setBookmarkSortBy(val);
                            }}
                            isCurrentPageLastIndex
                            isSort={!isEmpty(sortOption)}
                        />
                    </Stack>
                    <Box className={'scroll-arrow-container'}>
                        <Tablenization
                            Pagination={MorePageBox}
                            tableContainerRef={scrollerArrowRef}
                            count={count}
                            page={page}
                            cellWidth={12}
                            onChange={handlePageChange}
                            isPagination
                            isFixed
                        >
                            <Tablenization.TableRowHeader headerRow={headerRow} />
                            <Tablenization.TableRowBody
                                headerRow={headerRow}
                                dialogRef={memberDialogBoxRef}
                                rowsData={rowsData}
                                routerPath={MEMBERS_MEMBER_DETAIL_URL}
                                onOrderDialogClick={handleOrderDialogOpen}
                                checkBoxsRef={checkBoxsRef}
                                onCheckBoxChange={onCellChange}
                                getCheckedItems={getCheckedItems}
                            />
                        </Tablenization>
                        <ScrollerArrowButton parentRef={scrollerArrowRef} />
                    </Box>
                    <MemberDialogBox ref={memberDialogBoxRef} setContactState={setContactState} />
                </React.Fragment>
            )}
        </React.Fragment>
    );
});

export default AccountBookOverviewFilterTable;
