import React, { useRef, useState, useEffect, useImperativeHandle } from 'react';
import { v4 as uuid } from 'uuid';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import {
    Card,
    Typography,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    Checkbox,
    ListItemText,
    ListItemButton,
    TextField,
    Button,
    Stack,
    SelectField,
    MenuItem,
    Tooltip,
    Box,
} from '@common/components/';
import { Todo as TodoIcon, ErrorCircle as ErrorCircleIcon, Plus as PlusIcon, Minus as MinusIcon, Assignment as AssignmentIcon } from '@common/SvgIcon/';

const OtherSelectionBox = React.forwardRef((props, ref) => {
    const { options, label, className: classNameProps } = props;
    const [itemText, setItemText] = useState(false);
    const [isOtherText, setOtherText] = useState(false);
    const itemRef = useRef(null);
    const otherTextRef = useRef(null);

    const handleOnChange = (event, node, value) => {
        if (value === '其他') {
            setOtherText(true);
        } else {
            setOtherText(false);
        }
        setItemText(value);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let text = itemText;
                if (isOtherText) {
                    text = otherTextRef.current.getResult();
                }
                return text;
            },
        }),
        // eslint-disable-next-line
        [itemText]
    );

    useEffect(
        () => {
            if (isOtherText) {
                const node = otherTextRef.current.node();
                node.focus();
            }
        },
        // eslint-disable-next-line
        [isOtherText]
    );

    return (
        <Stack direction="column" spacing="1" className={clsx(classNameProps, 'flex-grow-1')}>
            <SelectField label={label} ref={itemRef} onChange={handleOnChange} fullWidth>
                {options.map(({ text, value, disabled }) => (
                    <MenuItem key={value} value={value} disabled={disabled}>
                        {text}
                    </MenuItem>
                ))}
            </SelectField>
            {isOtherText ? <TextField ref={otherTextRef} fullWidth /> : null}
        </Stack>
    );
});

const TodoListItem = ({ sourceData, onComplete: onCompleteProps, onDelete: onDeleteProps, hasDefaultIcon }) => {
    const { icon: Icon } = sourceData;

    const handleClick = (id) => {
        onCompleteProps && onCompleteProps(id);
    };
    return (
        <ListItem
            className={clsx('todo-list-item', {
                'todo-list-item-complete': sourceData.isCompleted,
            })}
            disableGutters
        >
            <ListItemButton className="p-1" role={undefined} onClick={() => handleClick(sourceData.id)} dense>
                <ListItemIcon>
                    <Checkbox key={sourceData.isCompleted} onChange={() => handleClick(sourceData.id)} defaultChecked={sourceData.isCompleted} disableRipple />
                </ListItemIcon>
                {hasDefaultIcon && <ListItemIcon>{Icon ? <Icon /> : <TodoIcon />}</ListItemIcon>}
                <ListItemText primary={sourceData.text} />
            </ListItemButton>
            <Tooltip title="刪除">
                <IconButton onClick={() => onDeleteProps(sourceData.id)}>
                    <ErrorCircleIcon />
                </IconButton>
            </Tooltip>
        </ListItem>
    );
};

const TodoListGroup = ({ todoList, hasIcon, onDeleteItem: onDeleteItemProps, onComplete: onCompleteProps }) => {
    return (
        <List className="todo-list">
            {Array.isArray(todoList) &&
                todoList.map((list) => {
                    return (
                        <TodoListItem
                            key={list.id}
                            sourceData={list}
                            onComplete={() => onCompleteProps(list)}
                            onDelete={onDeleteItemProps}
                            hasDefaultIcon={hasIcon}
                        />
                    );
                })}
        </List>
    );
};

const TodoListHeader = ({ title, isEdit, onEdit: onEditProps }) => {
    return (
        <Box component={'header'} className="todo-list-box-header">
            <AssignmentIcon color="primary" className="icon" />
            <Typography variant="h6" className="todo-list-box-title">
                {title}
            </Typography>
            <Tooltip title={isEdit ? '停止新增' : '新增'}>
                <IconButton className="todo-list-add" onClick={onEditProps}>
                    {isEdit ? <MinusIcon /> : <PlusIcon />}
                </IconButton>
            </Tooltip>
        </Box>
    );
};

const TodoListController = ({ edit, onAddTodo: onAddTodoProps, options }) => {
    const itemRef = useRef(null);
    const handleAddTodoEvent = (e) => {
        if (!/click|keydown/.test(e.type)) return;
        if (e.type === 'keydown' && e.key !== 'Enter') return;
        let text = itemRef.current && itemRef.current.getResult();
        if (!text || text.length < 1) return;
        onAddTodoProps && onAddTodoProps(text);
    };

    useEffect(() => {
        let itemDom = itemRef.current;
        if (!itemDom) return;
        if (!edit.show) return;
        let fieldDOM;
        if (itemDom.hasOwnProperty('node')) {
            fieldDOM = itemDom.node();
            fieldDOM.focus();
        }
    }, [edit, itemRef]);

    if (!edit.show) return null;

    return (
        <Stack alignItems="end" className="todo-list-controller">
            {options ? (
                Array.isArray(options) && <OtherSelectionBox ref={itemRef} key={edit.key} options={options} label="新增事項" className="mr-1" />
            ) : (
                <TextField label="新增事項" key={edit.key} ref={itemRef} className="mr-1" onKeyDown={handleAddTodoEvent} fullWidth />
            )}
            <Button variant="contained" className="todo-list-controller-btn" onClick={handleAddTodoEvent}>
                新增
            </Button>
        </Stack>
    );
};

const TodoList = (props) => {
    const { data: dataProps = [], title = '待辦事項', className, onAddTodoApi, onCompleteApi, onDeleteItemApi, hasIcon = false, variant, options } = props;
    const [edit, setEdit] = useState({ show: false, key: uuid() });
    const [sourceData, setSourceData] = useState(dataProps);

    let handleAddTodo = async (value = '') => {
        let todo;
        todo = {
            id: uuid(),
            isCompleted: false,
            text: value,
        };

        if (typeof onAddTodoApi === 'function') {
            let resp = await onAddTodoApi(todo);

            if (resp) {
                setSourceData((prev) => {
                    return [resp, ...prev];
                });
            }
        } else {
            setSourceData((prev) => {
                return [todo, ...prev];
            });
        }
        setEdit((prev) => ({ show: prev.show, key: uuid() }));
    };

    let handleOnEdit = () => {
        setEdit((prev) => ({ show: !prev.show, key: prev.key }));
    };

    let handleOnComplete = async (item) => {
        let target = { ...item, isCompleted: !item.isCompleted };
        if (typeof onCompleteApi === 'function') {
            let res = await onCompleteApi(target);
            if (res) {
                setSourceData((prev) => {
                    return prev.map((item) => {
                        if (item.id === target.id) item.isCompleted = !item.isCompleted;
                        return item;
                    });
                });
            }
        } else {
            setSourceData((prev) => {
                return prev.map((item) => {
                    if (item.id === target.id) item.isCompleted = !item.isCompleted;
                    return item;
                });
            });
        }
    };

    let handleOnDeleteItem = async (id) => {
        if (typeof onDeleteItemApi === 'function') {
            let res = await onDeleteItemApi(id);
            if (!res) return false;
        }

        setSourceData((prev) => {
            return prev.filter((item) => item.id !== id);
        });
    };

    useEffect(() => {
        if (!isEmpty(dataProps)) {
            setSourceData(dataProps);
        }
    }, [dataProps]);

    return (
        <Card className={clsx('todo-list-box', className)} variant={variant}>
            <Card.CardContent>
                <TodoListHeader title={title} isEdit={edit.show} onEdit={handleOnEdit} />
                <TodoListController edit={edit} onAddTodo={handleAddTodo} options={options} />
                <TodoListGroup todoList={sourceData} hasIcon={hasIcon} onDeleteItem={handleOnDeleteItem} onComplete={handleOnComplete} />
            </Card.CardContent>
        </Card>
    );
};

export default TodoList;
