import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';
import { Grid, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import { Alert, Box, Button, Card, CheckboxField, DateField, Form, MenuItem, SelectField, TextField, Typography } from '@common/components/';
import { Dollar as DollarIcon, Edit as EditIcon, UserCircle as UserCircleIcon } from '@common/SvgIcon/';
import UploadBox from '@icoach/components/UploadBox';
import PromptDialog from '@icoach/components/dialog/PromptDialog';
import { parseDate } from '@util/moment';
import useToggleDialog from '@util/hook/useToggleDialog';
import { pathRouterShit, getErrorModuleStatus } from '@util/utils';
import useShowPDF from '@util/hook/useShowPDF';
import useDocMembershipSuspendApi from '@apis/useDocMembershipSuspendApi';
import { default as termsData } from '@icoach/documents/membership/suspension/terms';
import MembershipSuspensionModifyCard from './MembershipSuspensionModifyCard';
import { FieldGroup } from '@icoach/documents/components/Layout';
import { TermContent } from '@icoach/documents/components/';
import { SignBox } from '@icoach/components/';
import { setCheckboxValue } from '@icoach/documents/tools';
import { DOCUMENTS_MEMBERSHIPSUSPENSION_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER } from '@icoach/router/routerPath';
import { dialogTypeMap } from '@icoach/documents/membership/staticData';
import { MembershipBillingRecordDialog, MembershipRecordDialog } from '@icoach/documents/membership/dialog';

const WorkspaceBox = (props) => {
    const { handleOpenDialog } = props;

    return (
        <Box className="workspace-box">
            <SpeedDial ariaLabel="SpeedDial openIcon example" icon={<SpeedDialIcon openIcon={<EditIcon />} />}>
                <SpeedDialAction icon={<UserCircleIcon />} tooltipTitle={'會籍紀錄'} onClick={() => handleOpenDialog(dialogTypeMap.membershipRecord)} />
                <SpeedDialAction
                    icon={<DollarIcon htmlColor="#a45bc8" />}
                    tooltipTitle={'收費明細'}
                    onClick={() => handleOpenDialog(dialogTypeMap.membershipBillingRecord)}
                />
            </SpeedDial>
        </Box>
    );
};

const MembershipSuspensionContent = (props) => {
    const { isReadOnly = false, onReturnHistory } = props;
    const {
        getDocSuspendContractApi,
        postDocSuspendContractApi,
        postDocTemporarySuspendContractApi,
        postUploadDocMembershipSuspendPlugin,
        downloadDocMembershipSuspendPDFApi,
    } = useDocMembershipSuspendApi();
    const history = useHistory();
    const { referenceID: referenceIDProps, contractID: contractIDProps, peopleType: peopleTypeProps } = useParams();
    const showPDF = useShowPDF();
    const { openTarget, handleOpenDialog, handleCloseDialog } = useToggleDialog();
    const [showPrompt, setShowPrompt] = useState(true);
    const [options, setOptions] = useState([]);
    const [isMounted, setMounted] = useState(false);
    const [errorModule, setErrorModule] = useState({});
    const temporarySourceDataRef = useRef({});
    const applyDateRef = useRef(null);
    const employeeIDRef = useRef(null);
    const uploadFileRef = useRef(null);
    const pathRef = useRef(null);
    const noteRef = useRef(null);
    const tokenRef = useRef(null);
    const isConfirmRef = useRef(null);
    const signImgRef = useRef(null);
    const membershipSuspensionModifyCardRef = useRef(null);
    const leaveReasonRef = useRef(null);
    const apiSendingRef = useRef(false);
    const { coachOptions = [] } = options;
    const {
        membershipID,
        contractID,
        memberID,
        applyDate,
        employeeID,
        plugIns = {},
        isConfirm,
        isCompleteContract, // 是否為正式文件
        signImg,
        contractVersion,
        contractVersionText,
        leaveReason,
    } = temporarySourceDataRef.current;

    const setParams = () => {
        if (isReadOnly) return false;
        const plug = {
            note: noteRef.current.getResult(),
            path: pathRef.current,
            token: tokenRef.current,
        };
        temporarySourceDataRef.current.applyDate = applyDateRef.current.getResult();
        temporarySourceDataRef.current.employeeID = employeeIDRef.current.getResult();
        temporarySourceDataRef.current.plugIns = plug;
        temporarySourceDataRef.current.isConfirm = isConfirmRef.current.getResult()[0].checked;
        temporarySourceDataRef.current.leaveReason = leaveReasonRef.current?.getResult() ?? '';
    };

    const getParams = () => {
        return { ...temporarySourceDataRef.current };
    };

    // 送出 按鈕
    const handleSubmitCreate = () => {
        if (apiSendingRef.current) return false;
        const file = uploadFileRef.current;
        temporarySourceDataRef.current.signImg = signImgRef.current.getResult();
        membershipSuspensionModifyCardRef.current.getResult();
        setParams();
        if (file) {
            apiSendingRef.current = true;
            postUploadFileDataApi(file, contractIDProps);
        } else {
            apiSendingRef.current = true;
            doMembershipSuspensionContractApi(contractIDProps, getParams());
        }
    };

    // 點擊暫存 按鈕
    const handleSubmitTemporary = () => {
        if (apiSendingRef.current) return false;
        setParams();
        membershipSuspensionModifyCardRef.current.getResult();
        apiSendingRef.current = true;
        doPostTemporaryDataApi(getParams());
    };

    // 上傳請假證明 事件
    const handleFileChange = (file) => {
        if (file) uploadFileRef.current = file;
    };

    // 上傳文件 API
    const postUploadFileDataApi = async (file, contractID) => {
        const formImgData = new FormData();
        formImgData.append('file', file, file.name);
        formImgData.append('contractID', contractID);
        const resp = await postUploadDocMembershipSuspendPlugin(contractID, formImgData);
        if (resp) {
            pathRef.current = resp;
            tokenRef.current = resp;
            setParams();
            doMembershipSuspensionContractApi(contractIDProps, getParams());
        } else {
            apiSendingRef.current = false;
        }
    };

    // 暫存 會籍暫停合約資料 API
    const doPostTemporaryDataApi = async (params) => {
        const resp = await postDocTemporarySuspendContractApi(params);
        if (resp) {
            setShowPrompt(false);
            onReturnHistory && onReturnHistory();
        } else {
            apiSendingRef.current = false;
        }
    };

    // 新增 會籍暫停合約資料 API
    const doMembershipSuspensionContractApi = async (contractID, params) => {
        const resp = await postDocSuspendContractApi(contractID, params);
        if (resp) {
            if (resp.isError) {
                setErrorModule(_.keyBy(resp.result, 'key'));
                apiSendingRef.current = false;
            } else {
                const routerPath = pathRouterShit(DOCUMENTS_MEMBERSHIPSUSPENSION_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER, {
                    peopleType: peopleTypeProps,
                    referenceID: referenceIDProps,
                    contractID: contractID,
                });
                showPDF({
                    open: true,
                    pdfUrl: resp.completedPath,
                });
                setShowPrompt(false);
                window.setTimeout(() => {
                    history.push(routerPath);
                }, 5);
                setErrorModule({});
            }
        } else {
            setErrorModule({});
            apiSendingRef.current = false;
        }
    };

    // 初始會籍暫停資料 API
    const doInitDocMembershipSuspendDataApi = async () => {
        const resp = await getDocSuspendContractApi(contractIDProps, { id: referenceIDProps });
        if (resp) {
            const { resource, ...others } = resp;
            temporarySourceDataRef.current = others;
            setOptions(resource);
            setMounted(true);
        }
    };

    const handleChangeApplyDate = (e, value) => {
        membershipSuspensionModifyCardRef.current.postApi({ applyDate: value });
    };

    useEffect(
        () => {
            doInitDocMembershipSuspendDataApi();
            return () => setMounted(false);
        },
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            {isMounted && (
                <React.Fragment>
                    <TermContent data={termsData[contractVersion] || []} className="mb-4" title="會員權暫停條款" subheader="依教育部體育署健身中心定型化契約" />
                    <Card>
                        <Card.CardContent className="px-4 pt-2 pb-1">
                            <Form name="create" onSubmit={handleSubmitCreate}>
                                <Box className="mt-3 mb-5">
                                    <FieldGroup label="表單資訊">
                                        <Grid item xs={4}>
                                            <DateField
                                                label="申請日期"
                                                ref={applyDateRef}
                                                defaultValue={parseDate(applyDate)}
                                                {...getErrorModuleStatus(errorModule, 'applyDate')}
                                                readOnly={isReadOnly}
                                                required
                                                fullWidth
                                                onChange={handleChangeApplyDate}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            {!_.isEmpty(coachOptions) && (
                                                <SelectField
                                                    label="經辦教練"
                                                    ref={employeeIDRef}
                                                    defaultValue={employeeID || null}
                                                    {...getErrorModuleStatus(errorModule, 'employeeID')}
                                                    readOnly={isReadOnly}
                                                    displayEmpty
                                                    required
                                                    fullWidth
                                                >
                                                    <MenuItem disabled value="">
                                                        <em>請選擇</em>
                                                    </MenuItem>
                                                    {coachOptions.map(({ text, value, disabled }) => (
                                                        <MenuItem key={value} value={value} disabled={disabled}>
                                                            {text}
                                                        </MenuItem>
                                                    ))}
                                                </SelectField>
                                            )}
                                        </Grid>
                                    </FieldGroup>
                                    <MembershipSuspensionModifyCard
                                        ref={membershipSuspensionModifyCardRef}
                                        applyDateRef={applyDateRef}
                                        errorModule={errorModule}
                                        isReadOnly={isReadOnly}
                                        memberID={referenceIDProps}
                                        contractID={contractIDProps}
                                        temporarySourceDataRef={temporarySourceDataRef}
                                        options={options}
                                    />
                                    {isCompleteContract && !isReadOnly && (
                                        <FieldGroup label="銷假原因" required>
                                            <Grid item xs={12}>
                                                <TextField
                                                    defaultValue={leaveReason}
                                                    ref={leaveReasonRef}
                                                    rows={6}
                                                    readOnly={isReadOnly}
                                                    multiline
                                                    required
                                                    fullWidth
                                                />
                                            </Grid>
                                        </FieldGroup>
                                    )}
                                    <FieldGroup label="茲附證明">
                                        <Grid item xs={12}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={6}>
                                                    <UploadBox
                                                        link={plugIns.path || null}
                                                        onFileChange={handleFileChange}
                                                        fileExtension={['jpg', 'png', 'bmp']}
                                                        readOnly={isReadOnly}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        label={`(字數限字100字)`}
                                                        ref={noteRef}
                                                        defaultValue={plugIns.notes || null}
                                                        maxLength={100}
                                                        readOnly={isReadOnly}
                                                        multiline
                                                        fullWidth
                                                        rows={4}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </FieldGroup>
                                    <FieldGroup label="注意事項">
                                        <Grid item xs={12}>
                                            <ol className="lists ol pl-3 mb-2">
                                                <li className="lists-item pt-1">
                                                    為配合銀行作業，會員須於暫停前最近一次扣款日之前十日辦理，暫停當月方可停扣月費，若辦理不及
                                                    則停扣作業將遞延，會員權益將不受影響。
                                                </li>
                                                <li className="lists-item">本文件辦理完畢後將直接生效，若有任何異動，仍需會員本人親自辦理。</li>
                                            </ol>
                                            <Alert severity="info" variant="filled" color="info" className="alert-justify-content-start">
                                                暫停期間建議保持適度運動，相信妳了解持續運動帶給妳的好處，記得定時補充水份，保持正面心情，教練期待妳的歸隊！
                                            </Alert>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CheckboxField
                                                ref={isConfirmRef}
                                                defaultChecked={setCheckboxValue(isConfirm)}
                                                {...getErrorModuleStatus(errorModule, 'isConfirm')}
                                                readOnly={isReadOnly}
                                                required
                                            >
                                                <CheckboxField.Checkbox label="本人已瞭解、詳讀會員權暫停條款，並同意遵守。" />
                                            </CheckboxField>
                                        </Grid>
                                        {!isReadOnly && (
                                            <Grid item xs={6}>
                                                <SignBox
                                                    ref={signImgRef}
                                                    title="會員簽名"
                                                    defaultValue={signImg}
                                                    {...getErrorModuleStatus(errorModule, 'signImg')}
                                                    readOnly={isReadOnly}
                                                />
                                            </Grid>
                                        )}
                                    </FieldGroup>
                                </Box>
                                <div className="btn-group text-center my-1">
                                    <Button variant="outlined" color="secondary" className="btn-minWidth" onClick={onReturnHistory}>
                                        返回
                                    </Button>
                                    {!isReadOnly && (
                                        <React.Fragment>
                                            {!isCompleteContract && (
                                                <Button variant="outlined" color="secondary" className="btn-minWidth" onClick={handleSubmitTemporary}>
                                                    暫存檔案
                                                </Button>
                                            )}
                                            <Button type="submit" variant="contained" color="secondary" className="btn-minWidth">
                                                {isCompleteContract ? '確認修改' : '確認送出'}
                                            </Button>
                                        </React.Fragment>
                                    )}
                                </div>
                                <Typography variant="body2" className="text-center mb-0 px-2 font-color-3 my-4">
                                    {contractVersionText}
                                </Typography>
                            </Form>
                        </Card.CardContent>
                    </Card>
                    {Boolean(membershipID) && (
                        <WorkspaceBox
                            memberID={memberID}
                            contractID={contractID}
                            isCompleteContract={isCompleteContract}
                            handleOpenDialog={handleOpenDialog}
                            downloadDocMembershipSuspendPDFApi={downloadDocMembershipSuspendPDFApi}
                        />
                    )}
                    <MembershipRecordDialog
                        open={openTarget === dialogTypeMap.membershipRecord}
                        memberID={memberID}
                        membershipID={membershipID}
                        onClose={handleCloseDialog}
                    />
                    <MembershipBillingRecordDialog
                        open={openTarget === dialogTypeMap.membershipBillingRecord}
                        memberID={memberID}
                        membershipID={membershipID}
                        onClose={handleCloseDialog}
                    />
                    {!isReadOnly && <PromptDialog when={showPrompt} />}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default MembershipSuspensionContent;
