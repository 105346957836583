import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { effectTypeMap } from '@icoach/documents/cyclePurchase/staticData';
import moment from 'moment';
import { refIsRequiredError } from '@util/utils';
import { isValidDate } from '@util/moment';
import { isEmpty } from 'lodash';
import { Card, CheckboxField, TextField } from '@common/components/';
import { Grid } from '@mui/material';
import { getLastShippingMonth, SectionHeader, SelectWithMenuItems, useSectionEffect } from '@icoach/documents/cyclePurchase/sections/CyclePurchaseUtility';

const QuitSection = forwardRef((props, ref) => {
    const {
        title = '退訂(限單獨申請)',
        effectDate,
        shippingDayData,
        data = {},
        resource = {},
        isCreate = false,
        setShowTip,
        onChangeEffectType: onChangeEffectTypeProps,
    } = props;
    const effectTypeID = effectTypeMap.quit;
    const { unsubscribeReasonOptions: reasonOptions } = resource;
    const [isShow, setShow] = useState(Boolean(isCreate));
    const [lastShippingMonth, setLastShippingMonth] = useState();
    const pickItemRef = useRef();
    const reasonRef = useRef();
    const otherRef = useRef();
    const isConfirmRef = useRef();

    const { effectID = '0' } = data;
    const isCreateEffect = String(effectID) === '0';
    const unsubscribeReason = isCreateEffect ? '' : data?.unsubscribeReason ?? '';
    const unsubscribeNote = isCreateEffect ? '' : data?.unsubscribeNote ?? '';

    const getMonth = (date) => {
        return moment(date).month() + 1;
    };

    const handleShowContent = (checked) => {
        setShow(checked);
    };

    const getResult = () => {
        let result = {};
        if (reasonRef.current && reasonRef.current.getResult) result.unsubscribeReason = reasonRef.current.getResult();
        if (otherRef.current && otherRef.current.getResult) result.unsubscribeNote = otherRef.current.getResult();

        return result;
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(reasonRef, otherRef, isConfirmRef),
            getEffectTypeID: () => isShow && effectTypeID,
            setHelperText: (isError, text) => {
                return pickItemRef.current && pickItemRef.current.setHelperText(isError, text);
            },
        }),
        // eslint-disable-next-line
        [isShow],
    );

    useEffect(
        () => {
            if (isValidDate(effectDate) && !isEmpty(shippingDayData)) {
                let _month = getLastShippingMonth(effectDate, shippingDayData.text);
                _month = getMonth(_month);
                setLastShippingMonth(_month);
            }
        },
        // eslint-disable-next-line
        [effectDate, shippingDayData],
    );

    useSectionEffect(setShowTip, onChangeEffectTypeProps, isShow, effectTypeID);

    useEffect(
        () => {
            if (!isEmpty(data.effectType)) {
                setShow(data.effectType.includes(effectTypeID));
            }
        },
        // eslint-disable-next-line
        [data],
    );

    return (
        <React.Fragment>
            <SectionHeader title={title} isShowCheckBox={!isCreate} refItem={pickItemRef} handleChange={handleShowContent} defaultChecked={isShow} />
            {isShow && (
                <Card.CardContent className={'p-4'}>
                    <Grid spacing={3} container>
                        <Grid xs={6} item>
                            <SelectWithMenuItems
                                refItem={reasonRef}
                                label="退訂原因"
                                defaultValue={unsubscribeReason}
                                options={reasonOptions}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <TextField
                                key={unsubscribeNote}
                                ref={otherRef}
                                label={'退訂原因補充說明'}
                                defaultValue={unsubscribeNote}
                                minRows={3}
                                multiline
                                fullWidth
                            />
                        </Grid>
                        {lastShippingMonth && (
                            <Grid xs={12} item>
                                <CheckboxField ref={isConfirmRef} required>
                                    <CheckboxField.Checkbox value={true} label={`我已確認將於${lastShippingMonth}月，收到最後乙次出貨。`} />
                                </CheckboxField>
                            </Grid>
                        )}
                    </Grid>
                </Card.CardContent>
            )}
        </React.Fragment>
    );
});

export default QuitSection;
