import React from 'react';
import { ListItemText, Menu, MenuItem } from '@common/components/';
import useShowPDF from '@util/hook/useShowPDF';

const BlankFormMenu = ({ anchorEl, source, open, handleClose }) => {
    const showPDF = useShowPDF();
    return (
        <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
            {Array.isArray(source) &&
                source.map(({ text, link }) => {
                    let url = link;
                    const handleClickPDF = () => {
                        showPDF({ open: true, pdfUrl: url });
                        handleClose();
                    };
                    return (
                        <MenuItem key={link} onClick={handleClickPDF}>
                            <ListItemText>{text}</ListItemText>
                        </MenuItem>
                    );
                })}
        </Menu>
    );
};

export default BlankFormMenu;
