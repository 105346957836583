import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { effectTypeMap } from '@icoach/documents/cyclePurchase/staticData';
import useMessageDialog from '@util/hook/useMessageDialog';
import useAuthUserApi from '@apis/useAuthUserApi';
import { v4 as uuid } from 'uuid';
import { isEmpty } from 'lodash';
import { getCheckboxValue } from '@icoach/documents/tools';
import { refIsRequiredError } from '@util/utils';
import { Button, Card, CheckboxField, TextField } from '@common/components/';
import { Grid } from '@mui/material';
import { AddressField } from '@icoach/documents/components';
import { SectionHeader } from '@icoach/documents/cyclePurchase/sections/CyclePurchaseUtility';

const ReceiverSection = forwardRef((props, ref) => {
    const { title = '收件人資訊', sourceData = {}, membershipData, isCreate = false, setShowTip, onChangeEffectType: onChangeEffectTypeProps } = props;
    const effectTypeID = effectTypeMap.receiver;
    const setMessageDialog = useMessageDialog();
    const { getAuthClubApi } = useAuthUserApi();
    const [isShow, setShow] = useState(Boolean(isCreate));
    const [defaultData, setDefaultData] = useState({
        source: sourceData,
        key: uuid(),
    });
    const [clubData, setClubData] = useState({});
    const { receiverName, receiverPostCode, receiverAddress, receiverMobile, receiverNotes } = defaultData.source;
    const pickItemRef = useRef(null);
    const receiverNameRef = useRef(null);
    const addressRef = useRef(null);
    const receiverMobileRef = useRef(null);
    const receiverNotesRef = useRef(null);
    const useClubAddressRef = useRef(null);

    const doAuthClubApi = async () => {
        let resp = await getAuthClubApi();
        if (resp) {
            setClubData(resp);
        }
    };

    const handleClick = () => {
        if (isEmpty(membershipData)) {
            setMessageDialog({
                open: true,
                title: '提醒',
                msg: '很抱歉！無法取得相關資料，謝謝。',
            });
        } else {
            console.log('membershipData', membershipData);
            setDefaultData({
                source: membershipData,
                key: uuid(),
            });
        }
    };

    const handleShowContent = (checked) => {
        setShow(checked);
    };

    const handleChangeClub = (e, value) => {
        if (value) {
            setDefaultData((prev) => ({
                ...prev,
                source: {
                    ...prev.source,
                    receiverAddress: clubData.cyclePurchaseAddress,
                },
            }));
        }
    };

    const getResult = () => {
        let result = {};
        if (receiverNameRef.current && receiverNameRef.current.getResult) result.receiverName = receiverNameRef.current.getResult();
        if (addressRef.current && addressRef.current.getResult) {
            const { postCode, address } = addressRef.current.getResult();
            result = {
                ...result,
                receiverPostCode: postCode,
                receiverAddress: address,
            };
        }
        if (receiverMobileRef.current && receiverMobileRef.current.getResult) result.receiverMobile = receiverMobileRef.current.getResult();
        if (receiverNotesRef.current && receiverNotesRef.current.getResult) result.receiverNotes = receiverNotesRef.current.getResult();
        if (useClubAddressRef.current && useClubAddressRef.current.getResult)
            result.isSendToStore = getCheckboxValue(useClubAddressRef.current.getResult(), {
                isMultiple: false,
            });

        return result;
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(receiverNameRef, addressRef, receiverMobileRef, receiverNotesRef),
            getEffectTypeID: () => isShow && effectTypeID,
            setHelperText: (isError, text) => {
                return pickItemRef.current && pickItemRef.current.setHelperText(isError, text);
            },
        }),
        // eslint-disable-next-line
        [isShow],
    );

    useEffect(() => {
        if (isShow) {
            setShowTip && setShowTip();
            onChangeEffectTypeProps && onChangeEffectTypeProps(isShow, effectTypeID);
            doAuthClubApi();
        }
        // eslint-disable-next-line
    }, [isShow]);

    useEffect(
        () => {
            if (!isEmpty(sourceData)) {
                setDefaultData({
                    source: sourceData,
                    key: uuid(),
                });
                if (!isEmpty(sourceData.effectType)) {
                    setShow(sourceData.effectType.includes(effectTypeID));
                }
            } else {
                setDefaultData({
                    source: {},
                    key: uuid(),
                });
            }
        },
        // eslint-disable-next-line
        [sourceData],
    );

    return (
        <React.Fragment>
            <SectionHeader title={title} isShowCheckBox={!isCreate} refItem={pickItemRef} handleChange={handleShowContent} defaultChecked={isShow} />
            {isShow && (
                <Card.CardContent key={defaultData.key} className={'p-4'}>
                    <Grid spacing={3} container>
                        <Grid xs={12} item>
                            <div className="tool-btn-group">
                                <Button color={'grey'} onClick={handleClick}>
                                    同會籍資料
                                </Button>
                            </div>
                        </Grid>
                        <Grid xs={4} item>
                            <TextField ref={receiverNameRef} label={'收件人'} defaultValue={receiverName} fullWidth required />
                        </Grid>
                        <Grid xs={4} item>
                            <TextField ref={receiverMobileRef} label={'收件手機'} defaultValue={receiverMobile} maskType={'MOB'} fullWidth required />
                        </Grid>
                        <Grid xs={12} item>
                            <CheckboxField ref={useClubAddressRef} onChange={handleChangeClub}>
                                <CheckboxField.Checkbox label={<span className={'font-weight-bold'}>寄至所屬店舖</span>} value={true} />
                            </CheckboxField>
                            <AddressField
                                ref={addressRef}
                                label={'收件地址'}
                                postCode={receiverPostCode}
                                address={receiverAddress}
                                addressPlaceholder={'請勿填寫便利商店地址、郵政信箱'}
                                containerSpacing={3}
                                required
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <TextField ref={receiverNotesRef} label={'備註'} defaultValue={receiverNotes} minRows={3} multiline fullWidth />
                        </Grid>
                    </Grid>
                </Card.CardContent>
            )}
        </React.Fragment>
    );
});

export default ReceiverSection;
