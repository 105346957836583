import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { effectTypeMap } from '@icoach/documents/cyclePurchase/staticData';
import useMessageDialog from '@util/hook/useMessageDialog';
import { v4 as uuid } from 'uuid';
import { isEmpty } from 'lodash';
import { refIsRequiredError } from '@util/utils';
import { Alert, Box, Button, Card } from '@common/components/';
import { Grid } from '@mui/material';
import CyclePurchaseInvoiceBox from '@icoach/documents/components/CyclePurchaseInvoiceBox';
import { SectionHeader, useSectionEffect } from '@icoach/documents/cyclePurchase/sections/CyclePurchaseUtility';

const InvoiceSection = forwardRef((props, ref) => {
    const {
        title = '發票資訊',
        sourceData = {},
        membershipData,
        errorModule,
        isCreate = false,
        options,
        setShowTip,
        onChangeEffectType: onChangeEffectTypeProps,
    } = props;
    const effectTypeID = effectTypeMap.invoice;
    const setMessageDialog = useMessageDialog();
    const [isShow, setShow] = useState(Boolean(isCreate));
    const [defaultData, setDefaultData] = useState({
        source: {},
        key: uuid(),
    });
    const pickItemRef = useRef(null);
    const invoiceRef = useRef(null);

    const getResult = () => {
        let result = {};

        if (invoiceRef.current && invoiceRef.current.getResult) {
            const { issuingEmail, ...invoice } = invoiceRef.current.getResult();
            result = Object.assign(result, {
                invoice: {
                    ...invoice,
                    mail: issuingEmail,
                },
            });
        }

        return result;
    };

    const handleClick = () => {
        const { invoice } = membershipData;

        if (isEmpty(invoice)) {
            setMessageDialog({
                open: true,
                title: '提醒',
                msg: '很抱歉！無法取得相關資料，謝謝。',
            });
        } else {
            setDefaultData({
                source: {
                    ...invoice,
                    issuingEmail: invoice.mail,
                    issuingPhone: invoice.cellPhone,
                },
                key: uuid(),
            });
        }
    };

    const handleShowContent = (checked) => {
        setShow(checked);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(invoiceRef),
            getEffectTypeID: () => isShow && effectTypeID,
            setHelperText: (isError, text) => {
                return pickItemRef.current && pickItemRef.current.setHelperText(isError, text);
            },
        }),
        // eslint-disable-next-line
        [isShow],
    );

    useSectionEffect(setShowTip, onChangeEffectTypeProps, isShow, effectTypeID);

    useEffect(
        () => {
            if (!isEmpty(sourceData)) {
                if (sourceData) {
                    setDefaultData({
                        source: sourceData,
                        key: uuid(),
                    });
                }
                if (!isEmpty(sourceData.effectType)) {
                    setShow(sourceData.effectType.includes(effectTypeID));
                }
            }
        },
        // eslint-disable-next-line
        [sourceData],
    );

    return (
        <React.Fragment>
            <SectionHeader title={title} isShowCheckBox={!isCreate} refItem={pickItemRef} handleChange={handleShowContent} defaultChecked={isShow} />
            {isShow && (
                <Card.CardContent key={defaultData.key} className={'p-4'}>
                    <Grid spacing={3} container>
                        <Grid xs={12} item>
                            <Box className="tool-btn-group">
                                <Button color={'grey'} onClick={handleClick}>
                                    同會籍資料
                                </Button>
                            </Box>
                            <br />
                            <Alert severity="info" variant="filled" color="info" className="alert-justify-content-start">
                                發票資訊變更將立即生效，若有其他選項變更將會以變更生效日為主
                            </Alert>
                        </Grid>
                        <Grid xs={8} item>
                            <CyclePurchaseInvoiceBox
                                className={'mb-2'}
                                ref={invoiceRef}
                                options={{
                                    invoiceCarrierTypeOptions: options.invoiceCarrierType || [],
                                    invoiceIssuingTypeOptions: options.invoiceIssuingType || [],
                                }}
                                sourceData={{
                                    ...defaultData.source,
                                    issuingPhone: defaultData.source.cellPhone || '',
                                    issuingEmail: defaultData.source.mail || '',
                                }}
                                errorModule={errorModule}
                            />
                            <Alert severity="info" variant="filled" color="info" className="alert-justify-content-start">
                                若要修改手機號碼，請至會員總覽基本資料處理。
                            </Alert>
                        </Grid>
                    </Grid>
                </Card.CardContent>
            )}
        </React.Fragment>
    );
});

export default InvoiceSection;
