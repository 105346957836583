import { useCallback } from 'react';
import useShowPDF from '@util/hook/useShowPDF';

const GET_DOCUMENT_CARD_AUTH_PDF_PATH_API = `/document/card-auth.pdf`;
const GET_DOCUMENT_LEADERBOARD_POSTER_API = `/document/leaderboard/poster/`;
const GET_DOCUMENT_TRANSCRIPT_API = `/document/leaderboard/transcript/`;
const GET_DOCUMENT_MULTIPLE_MEASURE_INFORMATION_API = (key) => `/document/${key}/measure/multiple-information/`;

const useDocumentApi = () => {
    const showPDF = useShowPDF();

    // 信用卡申請書
    const getDocumentCardAuthPDFDownloadApi = async () => {
        showPDF({
            open: true,
            pdfUrl: GET_DOCUMENT_CARD_AUTH_PDF_PATH_API,
        });
    };

    const getLeaderboardPDFApi = useCallback(
        async (params) => {
            const path = `${GET_DOCUMENT_LEADERBOARD_POSTER_API}?${params}`;
            showPDF({
                open: true,
                htmlUrl: path,
                pdfUrl: path,
            });
        },
        // eslint-disable-next-line
        []
    );
    // 成績單
    const getTranscriptPDFApi = useCallback(
        async (params, _key, _memberID) => {
            const path = `${GET_DOCUMENT_TRANSCRIPT_API}${params}`;
            showPDF({
                open: true,
                htmlUrl: path,
                pdfUrl: path,
                canDownload: false,
                canPrint: false,
                canQrCode: false,
            });
        },
        // eslint-disable-next-line
        []
    );

    const getMutipleMeasureInformationApi = useCallback(
        async (params, key, memberID) => {
            const path = `${GET_DOCUMENT_MULTIPLE_MEASURE_INFORMATION_API(key)}${params}`;
            showPDF({
                open: true,
                htmlUrl: path,
                pdfUrl: path,
                canDownload: false,
                canPrint: false,
                canQrCode: true,
                sourceData: { memberID },
            });
        },
        // eslint-disable-next-line
        []
    );

    return {
        getDocumentCardAuthPDFDownloadApi,
        getLeaderboardPDFApi,
        getTranscriptPDFApi,
        getMutipleMeasureInformationApi,
    };
};

export default useDocumentApi;
