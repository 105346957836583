import React from 'react';
import { Grid } from '@mui/material';
import { DateField, MenuItem, SelectField } from '@common/components/';
import { memberSheet, moreSheet, cellPhoneSheet, contactSheet, rowLabelCheckboxSheet, SpecialConditionBox } from '@icoach/components/';
import { refIsRequiredError } from '@util/utils';
import { parseDate } from '@util/moment';
import { MultipleSelectCheckedBox } from '@icoach/nonMember/components/NonMemberSearchBar';

// 月收費名單
export const FilterMonthlyFeeSearch = React.forwardRef((props, ref) => {
    const { resource = {} } = props;
    const {
        membershipTypeOption: membershipTypeOptionProps = [],
        paymentTypeOption: paymentTypeOptionProps = [],
        monthlyFeeOption: monthlyFeeOptionProps = [],
        receivedPaymentOption: receivedPaymentOptionProps = [],
    } = resource;

    const dateMonthRef = React.useRef(null);
    const membershipTypeRef = React.useRef(null);
    const paymentTypeRef = React.useRef(null);
    const membershipMonthlyFeeRef = React.useRef(null);
    const payablePriceRef = React.useRef(null);
    const receivedPaymentRef = React.useRef(null);

    React.useImperativeHandle(
        ref,
        () => {
            return {
                isError: () =>
                    refIsRequiredError(dateMonthRef, membershipTypeRef, paymentTypeRef, membershipMonthlyFeeRef, payablePriceRef, receivedPaymentRef),
                getResult: () => {
                    let result = {};
                    if (dateMonthRef.current && dateMonthRef.current.getResult) {
                        Object.assign(result, { date: parseDate(dateMonthRef.current.getResult(), 'YYYY-MM') });
                    }
                    if (membershipTypeRef.current && membershipTypeRef.current.getResult) {
                        Object.assign(result, { membershipType: membershipTypeRef.current.getResult() });
                    }
                    if (paymentTypeRef.current && paymentTypeRef.current.getResult) {
                        Object.assign(result, { paymentType: paymentTypeRef.current.getResult() });
                    }
                    if (membershipMonthlyFeeRef.current && membershipMonthlyFeeRef.current.getResult) {
                        Object.assign(result, { membershipMonthlyFeeType: membershipMonthlyFeeRef.current.getResult() || [] });
                    }
                    if (payablePriceRef.current && payablePriceRef.current.getResult) {
                        Object.assign(result, { payablePriceType: payablePriceRef.current.getResult() || [] });
                    }
                    if (receivedPaymentRef.current && receivedPaymentRef.current.getResult) {
                        Object.assign(result, { receivedPayment: receivedPaymentRef.current.getResult() });
                    }
                    return result;
                },
            };
        },
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            <Grid xs={3} item>
                <DateField
                    ref={dateMonthRef}
                    views={['year', 'month']}
                    openTo="month"
                    format="YYYY/MM"
                    label="月份"
                    mask="____/__"
                    defaultValue={parseDate(new Date(), 'YYYY-MM')}
                    required
                    fullWidth
                />
            </Grid>
            <Grid xs={3} item>
                <SelectField label={'會籍類別'} defaultValue={'99'} ref={membershipTypeRef} fullWidth>
                    {Array.isArray(membershipTypeOptionProps) &&
                        membershipTypeOptionProps
                            .filter((item) => item || !item.disabled)
                            .map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.text}
                                </MenuItem>
                            ))}
                </SelectField>
            </Grid>
            <Grid xs={3} item>
                <SelectField label={'繳費方式'} defaultValue={'99'} ref={paymentTypeRef} fullWidth>
                    {Array.isArray(paymentTypeOptionProps) &&
                        paymentTypeOptionProps
                            .filter((item) => item || !item.disabled)
                            .map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.text}
                                </MenuItem>
                            ))}
                </SelectField>
            </Grid>
            <Grid xs={3} item>
                <MultipleSelectCheckedBox label="月費" options={monthlyFeeOptionProps} ref={membershipMonthlyFeeRef} showLabel={true} />
            </Grid>
            <Grid xs={3} item>
                <MultipleSelectCheckedBox label="應收金額" options={monthlyFeeOptionProps} ref={payablePriceRef} showLabel={true} />
            </Grid>
            <Grid xs={3} item>
                <SelectField label={'收款狀態'} defaultValue={'99'} ref={receivedPaymentRef} fullWidth>
                    {Array.isArray(receivedPaymentOptionProps) &&
                        receivedPaymentOptionProps
                            .filter((item) => item || !item.disabled)
                            .map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.text}
                                </MenuItem>
                            ))}
                </SelectField>
            </Grid>
        </React.Fragment>
    );
});

export const FilterMonthlyFeeLabel = [
    {
        ...rowLabelCheckboxSheet,
        cellKey: 'memberID',
    },
    { ...memberSheet, isFixed: true, isFixedLeft: true, isShowAvatarImage: false },
    { ...cellPhoneSheet },
    {
        cellKey: 'specialCondition',
        headerLabel: '特殊狀況',
        width: 18,
        formatCell: (cellValue, { memberID }) => {
            return <SpecialConditionBox sourceData={{ memberID, value: cellValue }} />;
        },
    },
    {
        cellKey: 'receivedPaymentText',
        headerLabel: '收款狀態',
        align: 'center',
    },
    {
        cellKey: 'membershipTypeText',
        headerLabel: '會籍類別',
        align: 'center',
    },
    {
        cellKey: 'paymentTypeText',
        headerLabel: '繳款方式',
        width: 15,
        align: 'center',
    },
    {
        cellKey: 'cardExpiryDate',
        headerLabel: '信用卡期效',
        align: 'center',
        formatCell: (_value) => {
            if (!_value) return '-';
            else {
                let strArr = [];
                for (let i = 0; i < _value.length; i += 2) {
                    strArr.push(_value.slice(i, i + 2));
                }
                return strArr.join('/');
            }
        },
    },
    {
        cellKey: 'bankAccount',
        headerLabel: '銀行帳號末五碼',
        align: 'center',
        formatCell: (_value) => (!!_value ? _value : '-'),
    },
    {
        cellKey: 'membershipMonthlyFee',
        headerLabel: '月費',
        align: 'center',
    },
    {
        cellKey: 'payablePrice',
        headerLabel: '應收金額',
        align: 'center',
    },
    {
        cellKey: 'paidPrice',
        headerLabel: '已收金額',
        align: 'center',
    },
    {
        cellKey: 'invoiceNo',
        headerLabel: '發票號碼',
        align: 'center',
    },
    { ...contactSheet },
    { ...moreSheet },
];
