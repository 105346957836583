import React, { useEffect, useState } from 'react';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Dialog, DialogContent, Grid } from '@mui/material';
import { Typography, Box } from '@common/components/';
import useMemberTokenApi from '@apis/useMemberTokenApi';
import { ReadTextField } from '@icoach/components';
import { parseDate } from '@util/moment';
import { DateTimeStandard } from '../../../components/pickers/enums/DateTimeStandard';

const MemberSiteQrCodeDialog = (props) => {
    const { open = false, onClose, sourceData } = props;
    const { memberID = 0 } = sourceData || {};
    const { getMemberQrcodeApi } = useMemberTokenApi();
    const [data, setData] = useState({});

    const doMemberQrcodeApi = async (memberID) => {
        let resp = await getMemberQrcodeApi(memberID);
        if (resp) setData(resp);
    };

    useEffect(
        () => {
            if (open) {
                doMemberQrcodeApi(memberID);
            }
        },
        // eslint-disable-next-line
        [open],
    );

    return (
        <Dialog
            className={'qr-code-dialog'}
            open={open}
            // PaperProps={{ className: 'wx-65rem' }}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>請掃描登入會員</GradientColorHeader>
            <DialogContent>
                <Box className={'text-center'}>
                    <Grid container>
                        <Grid item xs={12}>
                            <img style={{ width: '280px', height: '280px' }} src={data.qrcode} alt={'qrcode'} />
                        </Grid>
                        <Grid item xs={12}>
                            <ReadTextField className={'captcha'} label={'驗證碼'}>
                                {data.captcha}
                            </ReadTextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'body2'}>請掃描上方QR Code，並輸入驗證碼，會員可瀏覽量身資訊。</Typography>
                            <Typography className={'font-color-3'} variant={'body2'}>{`登入資訊預計於${parseDate(
                                data?.expiredDate,
                                DateTimeStandard.DisplayFullBySlash,
                            )}失效`}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default MemberSiteQrCodeDialog;
