import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Switch, useHistory, useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { v4 as uuid } from 'uuid';
import { Typography, PrivateRoute } from '@common/components/';
import useDocMembershipApi from '@apis/useDocMembershipApi';
import useToggleDialog from '@util/hook/useToggleDialog';
import { peopleType } from '@apis/usePeopleApi';
import {
    DOCUMENTS_MEMBERSHIP_ROUTER,
    DOCUMENTS_MEMBERSHIP_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER,
    DOCUMENTS_MEMBERSHIP_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER,
    DOCUMENTS_MEMBERSHIP_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER,
    DOCUMENTS_MEMBERSHIP_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER_PATH,
    DOCUMENTS_MEMBERSHIP_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER_PATH,
} from '@icoach/router/routerPath';
import MembershipCreateContent from '@icoach/documents/membership/create/MembershipCreateContent';
import MembershipCreateHistory from '@icoach/documents/membership/create/MembershipCreateHistory';
import { DOC_MEMBERSHIP_LINK } from '@icoach/documents/membership/staticData';
import { BlankFormBox, SearchBar } from '@icoach/documents/components/';
import { ConfirmTemporaryContractDialog } from '@icoach/documents/dialog/';

const dialogTypeMap = {
    temporary: 'temporary',
};

const MembershipCreatePage = ({ location }) => {
    const { isApplySuccess } = location.state || {};
    const history = useHistory();
    const { peopleType: peopleTypeProps, referenceID: referenceIDProps } = useParams();
    const { getDocMembershipOptionApi, getDocMembershipTemporaryApi, deleteDocMembershipTemporaryApi } = useDocMembershipApi();
    const { openTarget, handleOpenDialog, handleCloseDialog } = useToggleDialog();
    const [isMounted, setMounted] = useState(false);
    const [options, setOptions] = useState({});
    const searchRef = useRef([]);
    const temporaryIDRef = useRef(0);

    // 前往新增/編輯合約頁
    const goToContractPage = (target) => {
        const { peopleType = peopleTypeProps, referenceID = referenceIDProps, contractID } = target;
        const path = DOCUMENTS_MEMBERSHIP_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER_PATH(peopleType, referenceID, contractID);
        history.push(path);
    };

    const checkPeopleType = (opts) => {
        const { targetOptions = [] } = opts;
        const isInclude = targetOptions.find(({ value }) => String(peopleTypeProps) === String(value));
        const defaultPeopleType = peopleType.customer;
        if (!peopleTypeProps || !isInclude) {
            history.replace(`${DOCUMENTS_MEMBERSHIP_ROUTER}${defaultPeopleType}`);
        }
    };

    // 文書重設 按鈕
    const handleResetClick = (type) => {
        const path = `${DOCUMENTS_MEMBERSHIP_ROUTER}${type}`;
        history.replace(path);
    };

    // 搜尋對象/返回 按鈕
    const handleSearchHistoryClick = (id, isMember) => {
        const { targetID, targetType } = searchRef.current.getResult();
        const refID = id || targetID;
        let refType = isMember ? 2 : targetType;
        const path = DOCUMENTS_MEMBERSHIP_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER_PATH(refType, refID);
        if (isMember) {
            history.replace(path);
            setOptions((prev) => cloneDeep(prev));
        } else {
            history.replace(path);
        }
    };

    // 新增文書 按鈕
    const handleCreateClick = () => {
        const { targetID: id, targetType: type } = searchRef.current.getResult();
        const params = { type, id };

        if (String(type) !== peopleType.onlyJoin) {
            doCreateBlankContractApi(params, type, id);
        } else {
            goToContractPage({
                peopleType: type,
                referenceID: id,
                contractID: 0,
            });
        }
    };

    // 彈出視窗 要刪除暫存資料
    const handleDialogNoClick = () => {
        const { targetID: id, targetType: type } = searchRef.current.getResult();
        doDeleteTemporaryDataApi(type, id);
    };

    // 彈出視窗 讀取上次暫存資料繼續編輯
    const handleDialogYseClick = () => {
        if (!temporaryIDRef.current) return;
        handleCloseDialog();
        goToContractPage({ contractID: temporaryIDRef.current });
    };

    // 刪除暫存合約資料
    const doDeleteTemporaryDataApi = async (type, id) => {
        const resp = await deleteDocMembershipTemporaryApi({ type, id });
        if (resp) {
            handleCloseDialog();
            goToContractPage({ contractID: 0 });
        }
    };

    // 新增空白頁面
    const doCreateBlankContractApi = async (params, type, id) => {
        let temporaryID;
        temporaryIDRef.current = 0;
        temporaryID = await getDocMembershipTemporaryApi(params);

        if (temporaryID !== 0 && !!temporaryID) {
            temporaryIDRef.current = temporaryID;
            handleOpenDialog(dialogTypeMap.temporary);
        } else {
            goToContractPage({
                peopleType: type,
                referenceID: id,
                contractID: 0,
            });
        }
    };

    // 初始 search options
    const doInitOptionsApi = async () => {
        const resp = await getDocMembershipOptionApi();
        if (resp) {
            checkPeopleType(resp);
            setOptions(resp);
        }
        setMounted(true);
    };

    const useSearchBarMemo = useMemo(
        () => {
            return (
                <SearchBar
                    key={uuid()}
                    ref={searchRef}
                    className="mb-4"
                    sourceOption={options.targetOptions}
                    onResetEvent={handleResetClick}
                    onCreateEvent={handleCreateClick}
                    onSearchHistoryEvent={handleSearchHistoryClick}
                    routerPath={DOCUMENTS_MEMBERSHIP_ROUTER}
                />
            );
        },
        // eslint-disable-next-line
        [options]
    );

    useEffect(
        () => {
            doInitOptionsApi();
            return () => setMounted(false);
        },
        // eslint-disable-next-line
        []
    );

    if (!isMounted) return null;

    return (
        <div className="container main-container-spacing">
            <header className={'page-header'}>
                <Typography className={'page-header-title'} variant={'h3'}>
                    入會申請書
                </Typography>
                <div className="btn-group">
                    <BlankFormBox source={DOC_MEMBERSHIP_LINK} />
                </div>
            </header>
            {useSearchBarMemo}
            <Switch>
                <PrivateRoute
                    exact
                    path={DOCUMENTS_MEMBERSHIP_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER}
                    as={MembershipCreateHistory}
                    onBlankContractClick={handleCreateClick}
                />
                <PrivateRoute
                    exact
                    path={DOCUMENTS_MEMBERSHIP_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER}
                    as={MembershipCreateContent}
                    onReturnHistory={handleSearchHistoryClick}
                />
                <PrivateRoute
                    exact
                    path={DOCUMENTS_MEMBERSHIP_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER}
                    as={MembershipCreateContent}
                    isReadOnly={true}
                    onReturnHistory={handleSearchHistoryClick}
                    isApplySuccess={isApplySuccess || false}
                />
            </Switch>
            <ConfirmTemporaryContractDialog
                open={openTarget === dialogTypeMap.temporary}
                handleClose={handleCloseDialog}
                handleClickYes={handleDialogYseClick}
                handleClickNo={handleDialogNoClick}
            />
        </div>
    );
};

export default MembershipCreatePage;
