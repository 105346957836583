import React, { useState } from 'react';
import { BlankFormMenu } from '@icoach/documents/components/';
import { Button } from '@common/components/';

const DocumentBlankValueButton = React.forwardRef((props, ref) => {
    const { sourceData = [] } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpenMenu = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <div className="btn-group">
            <Button variant="outlined" color="secondary" onClick={handleOpenMenu}>
                列印空白表單
            </Button>
            <BlankFormMenu anchorEl={anchorEl} source={sourceData} open={open} handleClose={handleCloseMenu} />
        </div>
    );
});


export default DocumentBlankValueButton;
