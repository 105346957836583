import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { Grid } from '@mui/material';
import { FieldGroup } from '@icoach/documents/components/Layout';
import { LeaveTextField } from '@icoach/documents/components/';
import { ReadTextField } from '@icoach/components/';
import { Alert, RadioField, TextField, DateField, CheckboxField } from '@common/components/';
import { displayDateText, parseDate, isValidDate } from '@util/moment';
import { isEmpty, matchOptionsText, isNumber, getErrorModuleStatus } from '@util/utils';
import useDocMembershipSuspendApi from '@apis/useDocMembershipSuspendApi';

const MembershipSuspensionModifyCard = React.forwardRef((props, ref) => {
    const { options, isReadOnly = false, temporarySourceDataRef, memberID, contractID, errorModule, applyDateRef } = props;
    const [refreshKey, setRefreshKey] = useState(uuid());
    const { postDocMembershipSuspendRange } = useDocMembershipSuspendApi();
    const { suspendTypeOptions, suspendReasonTypeOptions, paymentTypeOptions } = options;
    const {
        suspendType: suspendTypeProps,
        suspendReasonType: suspendReasonTypeProps,
        suspendReasonText: suspendReasonTextProps,
        suspendDay: suspendDayProps,
        suspendMonth: suspendMonthProps,
        suspendStartDate: suspendStartDateProps,
        suspendEndDate: suspendEndDateProps,
        isAllowSuspend: isAllowSuspendProps,
        warningMessage: warningMessageProps,
        paymentType: paymentTypeProps,
        debitDayOfMonth: debitDayOfMonthProps,
        membershipStartDate: membershipStartDateProps,
        membershipEndDate: membershipEndDateProps,
        membershipExtensionDate: membershipExtensionDateProps,
        visibleOverNextSuspend: visibleOverNextSuspendProps = false,
        recoveryDate: recoveryDateProps,
        suspensionDate: suspensionDateProps,
        unusedMonth: unusedMonthProps,
        unusedDay: unusedDayProps,
        usedMonth: usedMonthProps,
        usedDay: usedDayProps,
        isKeepDebit: isKeepDebitProps,
    } = temporarySourceDataRef.current;

    const [suspendType, setSuspendType] = useState(suspendTypeProps);
    const [suspendReasonType, setSuspendReasonType] = useState(suspendReasonTypeProps);
    const isInitComponent = useRef(true);
    const suspendReasonTextRef = useRef(null);
    const suspendDateLengthRef = useRef(null);
    const suspendStartDateRef = useRef(null);
    const isKeepDebitRef = useRef(null);

    const setParams = () => {
        const { month, day } = suspendDateLengthRef.current.getResult();
        temporarySourceDataRef.current.suspendType = suspendType;
        temporarySourceDataRef.current.suspendReasonType = suspendReasonType;
        temporarySourceDataRef.current.suspendReasonText = suspendReasonTextRef.current.getResult();
        temporarySourceDataRef.current.suspendDay = day || 0;
        temporarySourceDataRef.current.suspendMonth = month;
        temporarySourceDataRef.current.isKeepDebit = isKeepDebitRef.current.getResult()[0].checked;
    };

    // 設置區間參數 api回來的
    const setDateRangeParams = (params) => {
        const {
            isAllowSuspend,
            membershipStartDate,
            membershipEndDate,
            membershipExtensionDate,
            suspendEndDate,
            suspendStartDate,
            warningMessage,
            suspensionDate,
            recoveryDate,
            visibleOverNextSuspend,
            paymentType,
            debitDayOfMonth,
        } = params;
        if (isAllowSuspend) {
            temporarySourceDataRef.current.membershipStartDate = membershipStartDate;
            temporarySourceDataRef.current.membershipEndDate = membershipEndDate;
            temporarySourceDataRef.current.membershipExtensionDate = membershipExtensionDate;
            temporarySourceDataRef.current.suspendEndDate = suspendEndDate;
            temporarySourceDataRef.current.suspendStartDate = suspendStartDate;
            temporarySourceDataRef.current.suspensionDate = suspensionDate;
            temporarySourceDataRef.current.recoveryDate = recoveryDate;
            temporarySourceDataRef.current.visibleOverNextSuspend = visibleOverNextSuspend;
            temporarySourceDataRef.current.paymentType = paymentType;
            temporarySourceDataRef.current.debitDayOfMonth = debitDayOfMonth;
        }
        temporarySourceDataRef.current.isAllowSuspend = isAllowSuspend;
        temporarySourceDataRef.current.warningMessage = warningMessage;
    };

    /** 畫面資料api異動 暫停假別(suspendType) 暫停開始時間(suspendStartDateRef) 暫停多久-月(suspendDateLengthRef) 暫停多久-日(suspendDateLengthRef) */
    const getRangeParams = (args) => {
        const { suspendStartDate: startDate } = args || {};
        const suspendStartDate = startDate || suspendStartDateRef.current.getResult();
        const { month, day } = suspendDateLengthRef.current.getResult();
        const { applyDate } = args || {};
        setParams();
        return {
            applyDate: applyDate || parseDate(applyDateRef.current.getResult()) || null,
            contractID: contractID || 0,
            suspendType: suspendType,
            suspendStartDate: suspendStartDate,
            suspendMonth: month,
            isKeepDebit: isKeepDebitRef.current.getResult()[0].checked,
            suspendDay: day || 0, // 原件之後要改 undefined時裡面帶0
            memberID: memberID,
        };
    };

    // 轉換 暫停假別 事件
    const handleSuspendTypeChange = (e, value) => {
        setSuspendType(value);
    };

    // 暫停原因 事件 如果是其他原因說明為必填 9 為其他
    const handleSuspendReasonChange = (e, value) => {
        setSuspendReasonType(value);
    };

    // 變換 暫停開始時間 事件
    const handleSuspendDataChange = (e, value) => {
        temporarySourceDataRef.current.suspendStartDate = value; // 待處理
        const params = getRangeParams({ suspendStartDate: value });
        postCheckDateRangeApi(params);
    };

    // 變換 暫停多久-月 暫停多久-日 事件
    const handleDateLengthChange = (e) => {
        const params = getRangeParams();
        postCheckDateRangeApi(params);
    };

    const handleExtentionChange = () => {
        const params = getRangeParams();
        postCheckDateRangeApi(params);
    };

    // 檢查api range回來的資料
    const postCheckDateRangeApi = async (params) => {
        const { suspendType, suspendStartDate, suspendMonth, suspendDay } = params;
        if (!suspendType || !isValidDate(suspendStartDate) || !isNumber(suspendMonth) || !isNumber(suspendDay)) return;
        const resp = await postDocMembershipSuspendRange(params);
        if (resp) {
            setDateRangeParams(resp);
            setRefreshKey(uuid());
        }
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                getResult: () => {
                    setParams();
                },
                postApi: (args) => {
                    const params = getRangeParams(args);
                    postCheckDateRangeApi(params);
                },
            };
        },
        // eslint-disable-next-line
        [suspendType, suspendReasonType]
    );

    useEffect(
        () => {
            // 初始時不進入打api
            if (!isInitComponent.current) {
                const params = getRangeParams();
                postCheckDateRangeApi(params);
            }
            isInitComponent.current = false;
        },
        // eslint-disable-next-line
        [suspendType]
    );

    useEffect(
        () => {
            temporarySourceDataRef.current.suspendReasonType = suspendReasonType;
        },
        // eslint-disable-next-line
        [suspendReasonType]
    );

    const isShowDay = String(suspendType) !== '1';
    const showWarningMessage = !isAllowSuspendProps && !isEmpty(warningMessageProps);

    console.log(isKeepDebitProps);

    return (
        <React.Fragment key={refreshKey}>
            <FieldGroup label="暫停資訊">
                <Grid item xs={6}>
                    <ReadTextField label="待停扣月數">{unusedMonthProps}</ReadTextField>
                </Grid>
                <Grid item xs={6}>
                    <ReadTextField label="待停扣天數">{unusedDayProps}</ReadTextField>
                </Grid>
                <Grid item xs={6}>
                    <ReadTextField label="已停扣月數">{usedMonthProps}</ReadTextField>
                </Grid>
                <Grid item xs={6}>
                    <ReadTextField label="已停扣天數">{usedDayProps}</ReadTextField>
                </Grid>
            </FieldGroup>
            <FieldGroup label="暫停設定">
                <Grid item xs={12}>
                    <Alert severity="info" variant="filled" color="info" className="alert-justify-content-start">
                        我們邀請妳填寫以下資料，以協助妳完成後續的會員權暫停手續。
                    </Alert>
                </Grid>
                <Grid item xs={12}>
                    {!_.isEmpty(suspendTypeOptions) && (
                        <RadioField
                            label="暫停假別"
                            value={suspendType}
                            {...getErrorModuleStatus(errorModule, 'suspendType')}
                            readOnly={isReadOnly}
                            onChange={handleSuspendTypeChange}
                            required
                            row
                        >
                            {suspendTypeOptions.map(({ text, value }) => (
                                <RadioField.Radio key={value} value={value} label={text} />
                            ))}
                        </RadioField>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {!_.isEmpty(suspendReasonTypeOptions) && (
                        <RadioField
                            label="暫停原因"
                            value={suspendReasonType}
                            {...getErrorModuleStatus(errorModule, 'suspendReasonType')}
                            onChange={handleSuspendReasonChange}
                            readOnly={isReadOnly}
                            fullWidth
                            required
                            row
                        >
                            {suspendReasonTypeOptions.map((options) => (
                                <RadioField.Radio key={options.value} value={options.value} label={options.text} />
                            ))}
                        </RadioField>
                    )}
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        label="原因說明(字數限字20字)"
                        ref={suspendReasonTextRef}
                        defaultValue={suspendReasonTextProps}
                        {...getErrorModuleStatus(errorModule, 'suspendReasonText')}
                        required={String(suspendReasonType) === '9'}
                        readOnly={isReadOnly}
                        maxLength={20}
                        rows={2}
                        multiline
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4} />
                <Grid item xs={4}>
                    <DateField
                        label="暫停開始時間"
                        ref={suspendStartDateRef}
                        defaultValue={parseDate(suspendStartDateProps)}
                        onChange={handleSuspendDataChange}
                        {...getErrorModuleStatus(errorModule, 'suspendStartDate')}
                        readOnly={isReadOnly}
                    />
                </Grid>
                <Grid item xs={5}>
                    <LeaveTextField
                        label="暫停多久"
                        className="full-width"
                        ref={suspendDateLengthRef}
                        isShowDay={isShowDay}
                        dayProps={{
                            type: 'number',
                            defaultValue: suspendDayProps,
                            onChangeNative: handleDateLengthChange,
                            inputProps: {
                                min: 0,
                            },
                            disabled: isReadOnly,
                        }}
                        monthProps={{
                            type: 'number',
                            defaultValue: suspendMonthProps,
                            onChangeNative: handleDateLengthChange,
                            inputProps: {
                                min: 0,
                            },
                            disabled: isReadOnly,
                        }}
                        {...getErrorModuleStatus(errorModule, 'suspendDay')}
                        fullWidth
                        readOnly={isReadOnly}
                        required
                    />
                </Grid>
                <Grid item xs={8}>
                    <ReadTextField label="暫停期間">{displayDateText(suspendStartDateProps, suspendEndDateProps)}</ReadTextField>
                </Grid>
                {showWarningMessage && (
                    <Grid item xs={12}>
                        <Alert severity="error" variant="filled" color="error" className="alert-justify-content-start">
                            {warningMessageProps}
                        </Alert>
                    </Grid>
                )}
            </FieldGroup>
            <FieldGroup label="扣款資訊">
                <Grid item xs={4}>
                    {!_.isEmpty(paymentTypeOptions) && <ReadTextField label="付款方式">{matchOptionsText(paymentTypeOptions, paymentTypeProps)}</ReadTextField>}
                </Grid>
                <Grid item xs={4}>
                    <ReadTextField label="每月扣款日">{`${debitDayOfMonthProps} 號`}</ReadTextField>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                    <ReadTextField label="暫停扣款日">{parseDate(suspensionDateProps, 'YYYY/MM/DD')}</ReadTextField>
                </Grid>
                <Grid item xs={4}>
                    <ReadTextField label="恢復扣款日">{parseDate(recoveryDateProps, 'YYYY/MM/DD')}</ReadTextField>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={12}>
                    <CheckboxField ref={isKeepDebitRef} defaultChecked={[!!isKeepDebitProps]} readOnly={isReadOnly} onChange={handleExtentionChange}>
                        <CheckboxField.Checkbox label="此暫停申請書僅延長會籍，不執行停扣" value="isKeepDebit" />
                    </CheckboxField>
                </Grid>
                {visibleOverNextSuspendProps && (
                    <Grid item xs={8}>
                        <Alert severity="info" variant="filled" color="info" className="alert-justify-content-start">
                            因超過20日提出申請，暫停扣款將從次次月開始停扣
                        </Alert>
                    </Grid>
                )}
            </FieldGroup>
            <FieldGroup label="會籍異動">
                <Grid item xs={8}>
                    <ReadTextField label="會籍期間">{displayDateText(membershipStartDateProps, membershipEndDateProps)}</ReadTextField>
                </Grid>
                <Grid item xs={8}>
                    <ReadTextField label="更新後會籍">{displayDateText(membershipStartDateProps, membershipExtensionDateProps)}</ReadTextField>
                </Grid>
            </FieldGroup>
        </React.Fragment>
    );
});

export default MembershipSuspensionModifyCard;
