import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';

const GET_POINTS_REWARDS_ORDERS_OVERVIEW_OPTIONS_API = `/api/points/redeem-orders/overview/options/`;
const GET_POINTS_REWARDS_ORDERS_OVERVIEW_API = `/api/points/redeem-orders/overview/`;
const GET_POINTS_REWARDS_ORDERS_REMEEMITEMID_RECORDS_API = (redeemItemID) => `/api/points/redeem-orders/${redeemItemID}/records/`;

const usePointsApi = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    const getPointsRewardOrderOverviewOptionsApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_POINTS_REWARDS_ORDERS_OVERVIEW_OPTIONS_API);
            if (!resp) {
                enqueueSnackbar('讀取資料出錯，請重新整理畫面', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getPointsRewardOrderOverviewApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_POINTS_REWARDS_ORDERS_OVERVIEW_API, params);
            if (!resp) {
                enqueueSnackbar('讀取資料有誤，請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getPointsRewardOrderRecordsApi = useCallback(
        async (params, redeemItemId) => {
            const resp = await Ajax.get(GET_POINTS_REWARDS_ORDERS_REMEEMITEMID_RECORDS_API(redeemItemId), params);
            if (!resp) {
                enqueueSnackbar('讀取資料有誤，請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return { getPointsRewardOrderOverviewOptionsApi, getPointsRewardOrderOverviewApi, getPointsRewardOrderRecordsApi };
};

export default usePointsApi;
